import { IEventData, IMarketConfig, IOddData } from "./sportSlice";
interface ICalculateSpreadsOrPlayers {
  spreads: Record<string, IOddData[]>;
  selectedSpreads: Record<string, number | string>;
  players: Record<string, Record<string, IOddData[]>>;
  selectedPlayers: Record<string, Record<string, number | string>>;
}
/**calculate spreads &&players*/
export const calculateSpreadsOrPlayers = (
  events: IEventData[],
  selectedMarket?: string | number,
  marketsConfig?: IMarketConfig[],
  selectedGroup?: string | number
): ICalculateSpreadsOrPlayers => {
  let spreads: any = {};
  let selectedSpreads: Record<string, number | string> = {};
  let playersPerEvent: Record<string, Record<string, IOddData[]>> = {};
  let selectedPlayersPerEvent: Record<
    string,
    Record<string, number | string>
  > = {};
  let players: Record<string, IOddData[]> = {};
  let selectedPlayers: Record<string, number | string> = {};
  events.map((event: IEventData) => {
    const matchedMarketsWithSpread = event.markets.filter((market) =>
      marketsConfig?.find(
        (marketConfig) =>
          marketConfig.spread &&
          !marketConfig.player &&
          marketConfig.id === market.id &&
          (marketConfig.group_id === selectedGroup || marketConfig.main)
      )
    );
    const matchedMarketsWithPlayer = event.markets.filter((market) =>
      marketsConfig?.find(
        (marketConfig) =>
          marketConfig.id === market.id &&
          marketConfig.group_id === selectedGroup &&
          marketConfig.spread &&
          marketConfig.player
      )
    );
    if (selectedMarket !== "all") {
      const selectedMarketWithSpread = matchedMarketsWithSpread.find(
        (market) => market.id === selectedMarket
      );
      const selectedMarketWithPlayer = matchedMarketsWithPlayer.find(
        (market) => market.id === selectedMarket
      );
      if (selectedMarketWithSpread) {
        spreads = {
          ...spreads,
          [selectedMarketWithSpread.id]: spreads[selectedMarketWithSpread.id]
            ? removeOddDuplicates([
                ...spreads[selectedMarketWithSpread.id],
                ...selectedMarketWithSpread.odds,
              ]).sort((odd1: IOddData, odd2: IOddData) =>
                odd1.spread > odd2.spread
                  ? 1
                  : odd1.spread < odd2.spread
                  ? -1
                  : 0
              )
            : removeOddDuplicates(selectedMarketWithSpread.odds).sort(
                (odd1: IOddData, odd2: IOddData) =>
                  odd1.spread > odd2.spread
                    ? 1
                    : odd1.spread < odd2.spread
                    ? -1
                    : 0
              ),
          /**added  for spread logic change */
          events: spreads["events"]
            ? {
                ...spreads["events"],
                [event.id]: spreads["events"][event.id]
                  ? {
                      ...spreads["events"][event.id],
                      [selectedMarketWithSpread.id]: removeOddDuplicates(
                        selectedMarketWithSpread.odds
                      ).sort((odd1: IOddData, odd2: IOddData) =>
                        odd1.spread > odd2.spread
                          ? 1
                          : odd1.spread < odd2.spread
                          ? -1
                          : 0
                      ),
                    }
                  : {
                      [selectedMarketWithSpread.id]: removeOddDuplicates(
                        selectedMarketWithSpread.odds
                      ).sort((odd1: IOddData, odd2: IOddData) =>
                        odd1.spread > odd2.spread
                          ? 1
                          : odd1.spread < odd2.spread
                          ? -1
                          : 0
                      ),
                    },
              }
            : {
                [event.id]: {
                  [selectedMarketWithSpread.id]: removeOddDuplicates(
                    selectedMarketWithSpread.odds
                  ).sort((odd1: IOddData, odd2: IOddData) =>
                    odd1.spread > odd2.spread
                      ? 1
                      : odd1.spread < odd2.spread
                      ? -1
                      : 0
                  ),
                },
              },
        };
      } else if (selectedMarketWithPlayer) {
        players = {
          ...players,
          [selectedMarketWithPlayer.id]: removeOddDuplicates(
            selectedMarketWithPlayer.odds
          ),
        };
        playersPerEvent = {
          ...playersPerEvent,
          [event.id]: players,
        };

        selectedPlayers = {
          ...selectedPlayers,
          [selectedMarketWithPlayer.id]: selectedMarketWithPlayer.odds[0].extra,
        };
        selectedPlayersPerEvent = {
          ...selectedPlayersPerEvent,
          [event.id]: selectedPlayers,
        };
      }
    } else {
      if (matchedMarketsWithSpread) {
        matchedMarketsWithSpread.map((match) => {
          spreads = {
            ...spreads,
            [match.id]: spreads[match.id]
              ? removeOddDuplicates([...spreads[match.id], ...match.odds]).sort(
                  (odd1: IOddData, odd2: IOddData) =>
                    odd1.spread > odd2.spread
                      ? 1
                      : odd1.spread < odd2.spread
                      ? -1
                      : 0
                )
              : removeOddDuplicates(match.odds).sort(
                  (odd1: IOddData, odd2: IOddData) =>
                    odd1.spread > odd2.spread
                      ? 1
                      : odd1.spread < odd2.spread
                      ? -1
                      : 0
                ),
            /**added  for spread logic change */
            events: spreads["events"]
              ? {
                  ...spreads["events"],
                  [event.id]: spreads["events"][event.id]
                    ? {
                        ...spreads["events"][event.id],
                        [match.id]: removeOddDuplicates(match.odds).sort(
                          (odd1: IOddData, odd2: IOddData) =>
                            odd1.spread > odd2.spread
                              ? 1
                              : odd1.spread < odd2.spread
                              ? -1
                              : 0
                        ),
                      }
                    : {
                        [match.id]: removeOddDuplicates(match.odds).sort(
                          (odd1: IOddData, odd2: IOddData) =>
                            odd1.spread > odd2.spread
                              ? 1
                              : odd1.spread < odd2.spread
                              ? -1
                              : 0
                        ),
                      },
                }
              : {
                  [event.id]: {
                    [match.id]: removeOddDuplicates(match.odds).sort(
                      (odd1: IOddData, odd2: IOddData) =>
                        odd1.spread > odd2.spread
                          ? 1
                          : odd1.spread < odd2.spread
                          ? -1
                          : 0
                    ),
                  },
                },
          };
        });
      } else if (matchedMarketsWithPlayer) {
        matchedMarketsWithPlayer.map(
          (match) =>
            (players = {
              ...players,
              [match.id]: players[match.id]
                ? removeOddDuplicates([...players[match.id], ...match.odds])
                : removeOddDuplicates(match.odds),
            })
        );

        playersPerEvent = {
          ...playersPerEvent,
          [event.id]: players,
        };

        Object.keys(players).map((key) => {
          selectedPlayers = {
            ...selectedPlayers,
            [key]: players[key][0].extra,
          };
        });
        selectedPlayersPerEvent = {
          ...selectedPlayersPerEvent,
          [event.id]: selectedPlayers,
        };
      }
    }
  });
  Object.keys(spreads).map((key) => {
    if (key !== "events") {
      const marketOfSpread =
        marketsConfig && marketsConfig.find((el) => el.id === Number(key));
      const defaultSpread = marketOfSpread && marketOfSpread["spread_default"];
      const spreadExists = defaultSpread && defaultSpread.length > 0;
      selectedSpreads = {
        ...selectedSpreads,
        [key]: spreadExists ? Number(defaultSpread) : spreads[key][0].spread,
      };
    }
  });

  return {
    spreads: spreads,
    selectedSpreads: selectedSpreads,
    players: playersPerEvent,
    selectedPlayers: selectedPlayersPerEvent,
  };
};
const removeOddDuplicates = (arr: IOddData[]): IOddData[] => {
  const uniqueArray = arr.filter((elem, index, self) => {
    return (
      index ===
      self.findIndex((t) => {
        return t.spread === elem.spread;
      })
    );
  });
  return uniqueArray;
};

export const getTodayTimestamps = (): any => {
  const now = new Date();

  // Next 2 hours
  const next2Hours = new Date(now.getTime() + 2 * 60 * 60 * 1000).getTime();

  // Next 4 hours
  const next4Hours = new Date(now.getTime() + 4 * 60 * 60 * 1000).getTime();

  // End of today
  const endOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23, // Hour
    59, // Minute
    59, // Second
    999 // Millisecond
  ).getTime();

  return {
    hour2: Number(next2Hours),
    hour4: Number(next4Hours),
    today: Number(endOfToday),
  };
};
